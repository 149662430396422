import React from "react"
import { useRouter } from "next/router"

const SubDrawer = ({
  open,
  next,
  className,
  close,
  data,
  exit,
  handleSelectSubMenu,
}) => {
  const navExpand = "lg"

  const Router = useRouter()

  const handleExit = () => {
    Router.push("/")
    setTimeout(() => exit(), 1000)
  }

  const handleClick = (item) => {
    handleSelectSubMenu(item, () => next())
  }

  return (
    <div
      className={`side-drawer-mobile d-${navExpand}-none ${className} ${
        open ? "open" : ""
      }`}
    >
      <div className="nav-back">
        <div className="nav-item">
          <div className="nav-link" onClick={close}>
            <i className="ail ai-arrow-left" /> {data ? data?.title : null}
          </div>
        </div>
      </div>
      <div className="side-drawer-content">
        <ul className="nav-middle">
          {data?.children?.map((item) => (
            <li
              key={item.id}
              className="nav-item"
              onClick={() => handleClick(item)}
            >
              <div
                className={`nav-link ${
                  data?.title === "Special" ? "special" : ""
                } ${item.link === "logout" ? "logout" : "w-arrow"}`}
              >
                {item.title}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SubDrawer
