import React from "react"

//Components
import MainDrawer from "./components/main-drawer"
import SubDrawer from "./components/sub-drawer"

//Utils
import { useRouter } from "next/router"
import Cookies from "js-cookie"
import { login_link } from "components/links"

const MobileDrawer = ({ handleOpen, handleClose, isOpen }) => {
  const Router = useRouter()

  //States *************************************************************************** //

  const [{ isSubNav1, isSubNav2 }, setState] = React.useState({
    isMainNav: false,
    isSubNav1: false,
    isSubNav2: false,
  })

  const [drawerData, setDrawerData] = React.useState(null)
  const [subDrawerData, setSubDrawerData] = React.useState(null)
  //States *************************************************************************** //

  //Functions ************************************************************************ //
  const handleExit = () => {
    setState({ isSubNav1: false, isSubNav2: false })
    handleClose()
  }
  const handleSelectMenu = (item, fn) => {
    let { children, link } = item
    if (!children) {
      Router.push(link)
      handleExit()
    } else {
      setDrawerData(item)
      fn()
    }
  }

  const handleSelecSubMenu = (item, fn) => {
    let { children, link } = item
    if (link === "logout") {
      Cookies.remove(process.env.ACCESS_TOKEN)
      Cookies.remove(process.env.GUEST_TOKEN)
      window.location.href = login_link
    } else {
      if (!children) {
        Router.push(link)
        handleExit()
      } else {
        setSubDrawerData(item)
        fn()
      }
    }
  }

  return (
    // Just add subdrawer component if need more layer or can use loop for dynamic layers
    <>
      <MainDrawer
        open={isOpen}
        close={() => handleClose()}
        next={() => setState((prev) => ({ ...prev, isSubNav1: true }))}
        className="bglight"
        handleSelectMenu={handleSelectMenu}
      />
      <SubDrawer
        open={isSubNav1}
        close={() => setState((prev) => ({ ...prev, isSubNav1: false }))}
        className="bglight"
        data={drawerData}
        handleSelectSubMenu={handleSelecSubMenu}
        next={() => setState((prev) => ({ ...prev, isSubNav2: true }))}
      />
      <SubDrawer
        open={isSubNav2}
        close={() => setState((prev) => ({ ...prev, isSubNav2: false }))}
        className="bglight"
        data={subDrawerData}
        handleSelectSubMenu={handleSelecSubMenu}
      />
    </>
  )
}

export default MobileDrawer
