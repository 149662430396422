import React, { useState } from 'react';

//Components
import { Navbar, NavbarCheckout, } from "components/anti"
import MobileDrawer from './anti/navbar/mobile-drawer';

//utils
import { useRouter } from 'next/router'

const NavbarWrapper = ({ secondary, ...props }) => {
   const Router = useRouter();

   //States *************************************************************************** //
   const [state, setState] = useState({
      isOpen: null,
   })
   //States *************************************************************************** //

   //Functions ************************************************************************ //
   const handleOpenMainDrawer = (fn) => {
      setState(prev => ({ ...prev, isOpen: true }))
      if (fn) return fn();
   };

   const handleCloseMainDrawer = (fn) => {
      setState(prev => ({ ...prev, isOpen: false }));
      if (fn) fn();
   };
   //Functions ************************************************************************ //

   return (
      <>
         <MobileDrawer
            handleOpen={handleOpenMainDrawer}
            handleClose={handleCloseMainDrawer}
            isOpen={state.isOpen}
         />
         {secondary ?
            <NavbarCheckout {...props} />
            :
            <Navbar
               {...props}
               handleOpen={handleOpenMainDrawer}
            />
         }
      </>
   )
};

export default NavbarWrapper